<template>
	<div
		data-component="dds-prioritize-suits-field"
	>
		<label
			data-element="label"
		>
			DDS should prioritize the lowest cards in:
		</label>
		<div data-element="suit-checkboxes">
			<label data-element="suit-checkbox" v-for="suit in suitOptions" :key="suit.value">
				<checkbox-field
					:show-label="false"
					:name="suit.value"
					:id="suit.value"
					:checked="getIsChecked(suit.value)"
					@change="onChange($event, suit.value)"
				/>
				<suit-symbol
					:suitName="suit.value"
					:withoutPadding="true"
				/>
			</label>
		</div>
	</div>
</template>

<script>

	import CheckboxField from '@/components/forms/CheckboxField';
	import SuitSymbol from '@/components/diagrams/SuitSymbol';

	export default {
		components: {
			CheckboxField,
			SuitSymbol
		},
		props: {
			value: {
				type: Array,
				required: true,
				default () {
					return [];
				}
			}
		},
		data: () => ({
			suitOptions: [
				{
					value: 'C',
					symbol: '♣',
					colour: 'black'
				},
				{
					value: 'D',
					symbol: '♦',
					colour: 'red'
				},
				{
					value: 'H',
					symbol: '♥',
					colour: 'red'
				},
				{
					value: 'S',
					symbol: '♠',
					colour: 'black'
				}
			]
		}),
		computed: {
		},
		methods: {
			getIsChecked (suit) {
				return this.value.includes(suit);
			},
			onChange (checked, suit) {
				if (checked) {
					this.$emit('input', [...this.value, suit]);
				} else {
					this.$emit('input', this.value.filter(item => item !== suit));
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component="dds-prioritize-suits-field"] {

		[data-element="suit-checkboxes"] {
			display: flex;

			[data-element="suit-checkbox"] {
				display: flex;
				align-items: center;

				&:not(:last-child) {
					margin-right: rem(20);
				}

				::v-deep [data-component="checkbox-field"] {
					input {
						margin: auto rem(10) auto 0;
					}
				}

				::v-deep [data-component='suit-symbol'] {
					margin: 0 rem(1);

					[data-component='icon'] {
						margin-top: 0;
						width: rem(24);
						height: rem(24);
					}
				}
			}
		}
	}

</style>
