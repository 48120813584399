<template>
	<div data-component="edit-bot-cards-modal">
		<div data-element="form">
			<opening-lead-select-field
				:deal="deal"
				:excludeCards="getExcludedCardsForOpeningLead"
				v-model="openingLead"
			/>
		</div>
		<div data-element="cards-list">
			<div data-element="table-label">
				Cards to be played until student wins their first trick
			</div>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
			>
				<dynamic-table
					:columns="getColumns"
					:data="getTricks"
					overflow="visible"
				>
					<template #trick="{ index }">
						<b>{{ index + 1 }}</b>
					</template>
					<template #side="{ row, column, index }">
						<card-select-field
							renderOnChange
							:id="getId(index, column)"
							:rules="getRules(row, column, index)"
							:options="getCardsOptionsWithText(column, index)"
							:disabled="getCardSelectionDisabled(index, column)"
							:value="getCardForSide(row, column)"
							@input="handleCardSelection($event, index, column)"
						/>
					</template>
					<template #winner="{ row, index }">
						<b>{{ getWinnerSide(row, index) }}</b>
					</template>
					<template #action="{ index }">
						<div data-element="actions-cell">
							<btn
								v-if="getCanClear(index)"
								text="Clear"
								size="sm"
								@click.native="handleClear(index)"
							/>
							<btn
								v-if="getCanDelete(index)"
								text="Delete"
								colour="red"
								size="sm"
								@click.native="handleDelete(index)"
							/>
						</div>
					</template>
				</dynamic-table>
			</validation-observer>
		</div>
		<actions
			:actions="getActions"
			:isWaiting="isWaiting"
			:isDisabled="getIsDisabled"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import OpeningLeadSelectField from '@/components/dealLibrary/OpeningLeadSelectField';
	import CardSelectField from '@/components/dealLibrary/CardSelectField';
	import DynamicTable from '@/components/ui/views/dynamicTable/DynamicTable';
	import Btn from '@/components/ui/Btn.vue';
	import Actions from '@/components/ui/Actions';
	import api from '@/services/api';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import { partnerships, SEATS } from '@/consts';
	import { createSuitElement } from '@/helpers';
	import { Game } from '@bridge128/bridge128-game-logic-lib';

	export default {
		components: {
			OpeningLeadSelectField,
			CardSelectField,
			DynamicTable,
			Btn,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			openingLead: undefined,
			isWaiting: undefined,
			cards: [],
			suitsDataMap: {
				H: {
					symbol: '♥',
					colour: 'red'
				},
				D: {
					symbol: '♦',
					colour: 'red'
				},
				S: {
					symbol: '♠',
					colour: 'black'
				},
				C: {
					symbol: '♣',
					colour: 'black'
				}
			}
		}),
		computed: {
			getGameData () {
				if (this.deal) {
					return Game.fromState(this.deal.deal);
				}
				return null;
			},
			getContract () {
				return this.deal?.contract;
			},
			getContractSuit () {
				if (!this.getContract) {
					return '';
				}
				return this.getContract[1];
			},
			getDeclarer () {
				return this.deal?.declarer;
			},
			getDefenders () {
				if (!this.getDeclarer) {
					return false;
				}
				const defenders = partnerships
					.find((partnership) => {
						return !partnership.includes(this.getDeclarer);
					})
					.map(seat => SEATS[seat]);

				const openerSide = SEATS[this.getDeclarer].left;

				if (defenders[0]?.short !== openerSide) {
					const opener = defenders[1];
					defenders[1] = defenders[0];
					defenders[0] = opener;
				}

				return defenders;
			},
			getFirstDefenderSide () {
				if (this.getDefenders?.length) {
					return this.getDefenders[0]?.short;
				}
				return undefined;
			},
			getSecondDefenderSide () {
				if (this.getDefenders?.length) {
					return this.getDefenders[1]?.short;
				}
				return undefined;
			},
			getColumns () {
				const columns = [
					{
						label: 'Trick',
						key: 'trick'
					}
				];

				if (this.getDefenders) {
					columns.push(
						...this.getDefenders.map((defender, index) => {
							return {
								...defender,
								label: defender.name,
								key: 'side',
								index
							};
						}),
						{
							label: 'Winner',
							key: 'winner'
						},
						{
							label: '',
							key: 'action'
						}
					);
				}
				return columns;
			},
			getTricks () {
				if (this.openingLead && this.cards?.length) {
					const [firstTrick, ...rest] = this.cards;
					return [
						[
							{
								name: this.openingLead,
								side: this.getFirstDefenderSide
							},
							...firstTrick
						],
						...rest
					];
				}
				return [];
			},
			getWinners () {
				if (this.getTricks.length) {

					let winners = [];
					const [firstTrick, ...tricks] = this.getTricks;
					const firstTrickWinner = this.getTrickWinner(firstTrick, 0);

					if (firstTrickWinner || firstTrickWinner === 0) {
						winners = [firstTrickWinner];

						if (tricks?.length) {
							for (const index in tricks) {
								const trick = tricks[index];
								const prevWinner = winners[index];
								const winner = this.getTrickWinner(trick, prevWinner);
								if (winner || winner === 0) {
									winners = [...winners, winner];
								}
							}
						}
					}

					return winners;
				}
				return [];
			},
			getIfLastTrickSelected () {
				if (this.getTricks.length) {
					return this.getTricks[this.getTricks.length - 1].every(card => card.name);
				}
				return false;
			},
			getIsDisabled  () {
				if (!this.getIfLastTrickSelected) {
					return 'addTrick';
				}
				return undefined;
			},
			getExcludedCardsForOpeningLead () {
				return this.cards
					.flat()
					.filter((card, index) => {
						return index % 2 === 1;
					})
					.map(card => card.name);
			},
			getFilteredCards () {
				if (this.cards?.length) {
					const lastTrickIndex = this.cards.length - 1;
					return this.cards.map((trick, index) => {
						if (index === lastTrickIndex) {
							return trick.filter(card => card.name);
						}
						return trick;
					}).filter(trick => trick.length);
				}
				return [];
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							btnId: 'save',
							actionId: 'handleSave'
						},
						{
							text: 'Add trick',
							btnId: 'addTrick',
							actionId: 'handleAddTrick'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'close'
						}
					]
				};
			}
		},
		watch: {
			'deal.openingLead': {
				handler (value) {
					this.openingLead = value;
				},
				immediate: true
			},
			openingLead (value, oldValue) {
				if (value && !oldValue) {
					this.cards = [
						[
							{
								name: undefined,
								side: this.getSecondDefenderSide
							}
						]
					];
				}
				if (!value && oldValue) {
					this.cards = [];
				}
			}
		},
		created () {
			this.fetchCards();
		},
		methods: {
			close () {
				this.$emit('close');
			},
			getCardsOptions (column, index) {
				if (this.getGameData) {
					const player = this.getGameData[`player${column.short}`];
					const excludeCards = this.getExcludedCards(column, index);
					return player.handAsList
						.map(({ name }) => name)
						.filter(card => !excludeCards.includes(card));
				}
				return [];
			},
			getCardsOptionsWithText (column, index) {
				const options = this.getCardsOptions(column, index);
				return options.map(name => {
					const cardValue = name[0];
					const cardSuit = name[1];
					const suit = this.suitsDataMap[cardSuit];
					const text = `<b>${cardValue}</b> ${createSuitElement(suit.symbol, suit.colour)}`;
					return {
						value: name,
						text
					};
				});
			},
			getCardForSide (row, column) {
				if (row?.length) {
					const card = row[column.index];
					return card?.name;
				}
			},
			getId (index, column) {
				return `card-${index}-${column.index}`;
			},
			getRules (row, column, index) {
				const leadIndex = index === 0 ? 0 : this.getWinners[index - 1];
				let trickSuit;
				const leadCard = row[leadIndex];
				if (leadCard?.name) {
					trickSuit = leadCard.name[1];
				}
				const hand = this.getCardsOptions(column, index);
				return {
					required: leadIndex === column.index,
					canPlayCardToTrick: {
						trickSuit,
						hand
					}
				};
			},
			getExcludedCards (column, index) {
				return this.getTricks
					.map((trick, trickIndex) => index === trickIndex ? false : trick[column.index]?.name)
					.filter(Boolean);
			},
			getCardSelectionDisabled (index, column) {
				if (!this.openingLead) {
					return true;
				}

				return index === 0 && column.index === 0;
			},
			handleCardSelection (card, index, column) {
				if (index === 0) {
					this.$set(this.cards[index][0], 'name', card);
				} else {
					this.$set(this.cards[index][column.index], 'name', card);
				}
			},
			handleAddTrick () {
				if (this.getIfLastTrickSelected) {
					this.cards = [
						...this.cards,
						[
							{
								name: undefined,
								side: this.getFirstDefenderSide
							},
							{
								name: undefined,
								side: this.getSecondDefenderSide
							}
						]
					];
				}
			},
			parseCard (cardString) {
				if (cardString && this.getGameData) {
					return this.getGameData.deck.card(cardString);
				}
			},
			getTrickWinner (trick, prevTrickWinner) {
				if (trick.filter(card => card.name).length === 2) {
					const leadIndex = prevTrickWinner;
					const partnerIndex = prevTrickWinner === 0 ? 1 : 0;

					const leadCard = this.parseCard(trick[leadIndex].name);
					const partnerCard = this.parseCard(trick[partnerIndex].name);
					const sameSuits = leadCard.suitName === partnerCard.suitName;

					if (sameSuits) {
						return leadCard.isHigherThan(partnerCard) ? leadIndex : partnerIndex;
					} else if (leadCard.suitName === this.getContractSuit) {
						return leadIndex;
					} else if (partnerCard.suitName === this.getContractSuit) {
						return partnerIndex;
					}
					return leadIndex;
				}
				return null;
			},
			getWinnerSide (trick, index) {
				const winnerIndex = this.getWinners[index];
				if (winnerIndex || winnerIndex === 0) {
					return trick[winnerIndex]?.side;
				}
				return '';
			},
			populateCards (cards) {
				if (cards?.length === 0 && this.deal?.openingLead) {
					this.cards = [
						[
							{
								name: undefined,
								side: this.getSecondDefenderSide
							}
						]
					];
				} else {
					const lastTrickIndex = cards.length - 1;
					this.cards = cards.map((trick, index) => {
						if (
							lastTrickIndex !== 0 &&
							lastTrickIndex === index &&
							trick.length === 1
						) {
							const card = trick[0];
							if (this.getFirstDefenderSide === card?.side) {
								return [
									card,
									{
										name: undefined,
										side: this.getSecondDefenderSide
									}
								];
							}
							return [
								{
									name: undefined,
									side: this.getFirstDefenderSide
								},
								card
							];
						}
						return trick;
					});
				}
			},
			async fetchCards () {
				const response = await api.deals.getDealPredefinedCards({
					dealId: this.$route.params.dealId
				});

				if (response) {
					this.populateCards(response);
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			},
			async updateDeal () {
				const deal = await api.deals.updateDeal({
					dealId: this.$route.params.dealId,
					deal: {
						...this.deal,
						openingLead: this.openingLead
					}
				});
				if (this.deal && deal) {
					this.deal.openingLead = deal.openingLead;
					return true;
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			},
			async updateBotCards () {
				const cards = await api.deals.updateDealPredefinedCards({
					dealId: this.$route.params.dealId,
					cards: this.getFilteredCards
				});
				if (cards) {
					this.populateCards(cards);
					return true;
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					return;
				}

				this.isWaiting = 'save';

				const dealResult = await this.updateDeal();
				const cardsResult = await	this.updateBotCards();

				if (dealResult && cardsResult) {
					this.$store.commit('ui/showNotification', {
						notification: 'Successfully saved'
					});
				}
				this.isWaiting = undefined;
			},
			getCanClear (index) {
				const tricksMaxIndex = this.getTricks.length - 1;
				return tricksMaxIndex === index;
			},
			getCanDelete (index) {
				if (index === 0) {
					return false;
				}
				return this.getCanClear(index);
			},
			handleClear (index) {
				this.cards = this.cards.map((trick, i) => {
					if (index === i) {
						return trick.map(card => ({
							...card,
							name: undefined
						}));
					}
					return trick;
				});
			},
			handleDelete (index) {
				if (index !== 0) {
					this.cards = this.cards.filter((_, i) => index !== i);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='edit-bot-cards-modal'] {
		[data-element='form'] {
			margin: 0;

			::v-deep [data-component='opening-lead-select'] {
				[data-component='select-field'] {
					margin-top: 0;
				}
			}

			::v-deep [data-component='dynamic-table'] {
        @media (max-width: 1279px) {
          overflow: auto !important;
          min-height: 225px;
        }

				[data-element='table-column-side'] {
					[data-component='select-field'] {
						margin: 0;
					}
				}
			}
		}

		[data-element='cards-list'] {
			margin-bottom: rem(28);

			[data-element='table-label'] {
				display: block;
				font-size: rem(18);
				line-height: 1;
				margin-bottom: rem(12);
				font-weight: 700;

				&[data-required] {
					&:after {
						margin: 0;
						content: '*';
						display: inline;
						color: $c-red;
					}
				}

				&[data-error] {
					color: $c-red;
				}
			}

			::v-deep [data-component='dynamic-table'] {
				[data-element='table-column-trick'],
				[data-element='table-column-side'],
				[data-element='table-column-winner'],
				[data-element='table-column-action'] {
					vertical-align: top;
				}

				[data-element='table-column-winner'] {
					text-align: center;
				}

				[data-element='table-column-trick'],
				[data-element='table-column-winner'] {
					width: 70px;
				}

				[data-element='table-column-side'] {
					width: 160px;
				}

				td {
					&[data-element='table-column-trick'],
					&[data-element='table-column-winner'] {
						padding-top: rem(15);
					}
					&[data-element='table-column-action'] {
						padding-top: rem(12);
					}
				}

				[data-element='table-column-side'] {
					text-align: left;

					[data-component='select-field'] {
						margin: 0;
					}
				}

				[data-element='table-column-action'] {
					width: 200px;

					[data-element='actions-cell'] {
						display: flex;
						justify-content: flex-end;

						[data-component='btn'] {
							margin-left: rem(10);
						}
					}
				}
			}
		}

		::v-deep [data-component='actions'] {
			padding: 0 0 rem(10) 0;

      @media (max-width: 1279px) {
        flex-direction: column;
        gap: 12px;

        [data-element="actions-primary"] {
          margin: 0;
          width: 100%;
          justify-content: space-between;

          [data-component="btn"] {
            margin: 0
          }
        }

        [data-element="actions-secondary"] {
          margin: 0;
          width: 100%;
          justify-content: space-between;

          [data-component="btn"] {
            margin: 0;
            width: 100%
          }
        }
      }
		}
	}

</style>
